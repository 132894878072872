<template>
  <v-menu transition="slide-y-transition" nudge-left="40" offset-y v-model="showMenu">
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on">
        <v-btn :class="body" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card class="mx-auto" max-width="300">
      <v-list dense>
        <v-list-item v-for="(action, i) in actions" :key="i" :disabled="action.disabled"
          @click.stop="actionClicked(action.component)">
          <v-list-item-action :disabled="action.disabled">
            <v-icon>{{ action.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content class=" mr-3">
            <v-list-item-title :class="body">{{ action.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         <v-list-item :disabled="!orderGridSubmitEnabled" @click.stop="submitOrderGrid(ad)">
          <v-list-item-action>
             <v-icon>
              mdi-email-fast-outline
            </v-icon>
           </v-list-item-action>
          <v-list-item-content class=" mr-3">
             <v-list-item-title :class="body">
               Send To Order Grid
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <span v-if="['Draft', 'Submitted'].includes(ad.status)">
          <v-list-item :disabled="ad.is_processing" @click.stop="generateAdPlanner(ad)">
            <v-list-item-action :disabled="!['Draft', 'Submitted'].includes(ad.status) || ad.is_processing">
              <span v-if="['Draft', 'Submitted'].includes(ad.status)">
                <v-icon>
                  mdi-clock-start
                </v-icon>
              </span>
            </v-list-item-action>
            <v-list-item-content class=" mr-3">
              <span v-if="['Draft', 'Submitted'].includes(ad.status)">
                <v-list-item-title :class="body">
                  Generate Ad Planner
                </v-list-item-title>
              </span>
            </v-list-item-content>
          </v-list-item>
        </span>
        <span v-if="['Approved'].includes(ad.status) && ['awg', 'alliance-retail-group'].includes($auth.tenant) && ad.ad_planner_generated">
          <v-list-item :disabled="ad.is_processing"
            @click.stop="actionClicked('GenAdPlanFiles')">
            <v-list-item-action :disabled="ad.is_processing">
              <span>
                <v-icon>
                  mdi-clock-start
                </v-icon>
              </span>
            </v-list-item-action>
            <v-list-item-content class=" mr-3">
              <span>
                <v-list-item-title :class="body">
                  Generate Ad Planner Files
                </v-list-item-title>
              </span>
            </v-list-item-content>
          </v-list-item>
        </span>
        <span v-if="ad.ad_planner_generated">
          <v-list-item @click.stop="component = 'ad_planner_view', modal = true">
            <v-list-item-action>
              <span v-if="ad.ad_planner_generated">
                <v-icon>mdi-text</v-icon>
              </span>
            </v-list-item-action>
            <v-list-item-content class=" mr-3">
              <span v-if="ad.ad_planner_generated">
                <v-list-item-title :class="body">
                  View Ad Planner
                </v-list-item-title>
              </span>
            </v-list-item-content>
          </v-list-item>
        </span>
      </v-list>
      <Component 
        :key="componentKey" 
        :is="component" 
        :value.sync="modal"
        :selectedAd="ad" 
        :defaultParty="adParty"
        :parties="adGroups" 
        :direction="direction"
        @refresh="$emit('refresh')"
        @closeAndRefresh="closeAndRefresh"
      />
    </v-card>
  </v-menu>
</template>
<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { notification } from '@/mixins/notification'
import { userAccess } from '@/mixins/user-access'
export default {
  name: 'AdActions',
  components: {
    AdCopy: () => import('@/components/ads/ad-copy'),
    AdNotes: () => import('@/components/ads/ad-notes'),
    AdShuttle: () => import('@/components/ads/ad-shuttle'),
    AdView: () => import('@/components/ads/ad-view'),
    ad_planner_view: () => import('@/components/ads/ad-planner-read-only'),
    GenAdPlanFiles: () => import('@/components/ads/gen-adplan-files')
  },
  mixins: [userAccess, displayHelpers, notification],
  props: [
    'ad',
    'adParty',
    'allowRefresh',
  ],
  data() {
    return {
      component: '',
      componentKey: 0,
      modal: false,
      showMenu: false,
      direction: '',
      isGeneratingAdPlanner: false
    }
  },
  computed: {
    adGroups() {
      if (this.limitAccessByRelatedAdGroups) {
        return this.$store.getters.adGroups.filter(adGroup => this.userAdGroupIds.includes(adGroup?.party_id))
      } else {
        return this.$store.getters.adGroups
      }
    },
    partyTypeName() {
      return this.adParty?.party_type?.constant
    },
    actions() {
      return [
        { label: 'Edit', component: 'AdView', icon: 'mdi-pencil-outline', disabled: false || this.ad.is_processing, show: true} ,
        { label: 'Notes', component: 'AdNotes', icon: 'mdi-comment-text-multiple-outline', disabled: false, show: true },
        { label: 'Shuttle', component: 'AdShuttle', icon: 'mdi-bus-multiple', disabled: false || this.ad.is_processing, show: this.$auth.tenant !== 'pricechopper' },
        { label: 'Clone', component: 'AdCopy', icon: 'mdi-content-duplicate', disabled: this.partyTypeName !== 'AD_GROUP' || this.ad.is_processing, show: true }
      ].filter(action => action.show)
    },
    orderGridSubmitEnabled() {
      if (this.ad.ad_planner_generated && !this.ad.order_grid_submitted) {
        if (this.$auth.tenant === 'pricechopper' && this.ad.status === 'Approved' && this.hasStatusAdminAccess) {
          return true
        }
        if (this.$auth.tenant === 'alliance-retail-group' && this.ad.status === 'Approved') {
          return true
        }
        if (this.$auth.tenant !== 'alliance-retail-group' && this.$auth.tenant !== 'pricechopper') {
          return true
        }
      }
      return false
    },
  },
  methods: {
    actionClicked(actionComponent) {
      this.component = actionComponent
      this.modal = true
    },
    closeAndRefresh() {
      this.modal = false
      this.$emit("update:allowRefresh", true)
      this.$emit('refresh')
    },
    async generateAdPlanner(ad) {
      if (this.isGeneratingAdPlanner == true) {
        return;
      }
      else {
        this.isGeneratingAdPlanner = true
        const data = { status: ad.status }
        const now = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Chicago" }));
        const start = new Date(now).setHours(20, 30, 0);
        const end = new Date(now).setHours(22, 30, 0);
        if (now >= start && now <= end) {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Ad Planner Cannot be Generated Between 8:30PM and 10:30PM CST` })
        } else {
          await this.$Ads.generateAdPlanner(ad.id, data).then(res => {
            if (res.data) {
              this.$store.dispatch('setSnackbar', { status: 'success', text: `Ad Planner Generating` })
            }
          }).catch(err => {
            this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Generate Due to ${err?.response?.data?.message || err.message}` })
          })
        }
        this.$emit('refresh')
        this.isGeneratingAdPlanner = false
      }
    },
    async submitOrderGrid() {
      const ad = this.ad
      const AdData = {
        ad_name: ad.ad_name,
        ad_party_id: ad.ad_party_id,
        ad_start_date: ad.ad_start_date,
        ad_end_date: ad.ad_end_date,
        notes: ad.notes,
        ad_type: ad.ad_type.id,
        classification: ad.classification,
        first_ship_date: ad.first_ship_date,
        second_ship_date: ad.second_ship_date,
        status: ad.status,
        order_grid_submitted: "True"
      }
      await this.$Ads.updateAd(ad.id, AdData).then(res => {
        if (res.data) {
          this.$store.dispatch('setSnackbar', { status: 'success', text: `Sent to Order Grid` })
        }
      }).catch(err => {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Send to Order Grid with ${err?.response?.data?.message || err.message}` })
      })
      const NoteData = {
        ad_id: ad.id,
        note_text: "Ad was Sent to Order Grid"
      }
      await this.$Ads.saveAdNote(NoteData).catch(err => {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Save Note ${err?.response?.data?.message || err.message}` })
      })
      this.$emit('refresh')
    }
  },
  watch: {
    modal: {
      immediate: false,
      handler() {
        this.componentKey += 1
        if (!this.modal) this.showMenu = false
        this.$emit("update:allowRefresh", !this.allowRefresh)
      }
    }
  }
}
</script>
  
<style scoped>
.active {
  box-shadow: 0px 2px 10px 3px rgba(120, 120, 120, 0.75);
  background: #385F73;
  color: white !important;
}

.filtered {
  background: #FFC107 !important;
  color: white !important;
}
</style>